@import "ui/variables";

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset
@import "bootstrap/normalize";
@import "bootstrap/print";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/glyphicons";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";


@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,500,700);


body
{
    font-family: 'Roboto', sans-serif;
    min-width: 1020px;
    background: $body-background-color;
    padding: 0;
}

.condensed
{
    font-family: 'Roboto Condensed', sans-serif;
}
.container
{
    width: 1020px !important;
    padding: 0;
}

a:hover, a:focus {
    text-decoration: none;
}

.addthis_button_email .at300bs
{
    display:none;
}

.padv
{
    padding-top:15px;
    padding-bottom:15px;
}

@import "ui/fontello";
@import "ui/select";
@import "ui/typo";
@import "ui/header";
@import "ui/footer";
@import "ui/categorias";
@import "ui/main";
@import "ui/flexslider";
@import "ui/produtos";
@import "ui/marcas";
@import "ui/paginas";
@import "ui/botoes";
@import "ui/modals";
@import "ui/tables";
@import "ui/carrinho";
@import "ui/areapessoal";
@import "ui/forms";
@import "ui/tabs";
@import "ui/apoio";
@import "ui/newsletter";
@import "ui/cookie";
@import "vendor/featherlight";
