.btn-share
{
    font-weight: $font-light;
    font-size: 14px;
    background: #E3EAE6;
    color: #1D8052;
    padding: 10px 25px;
    &:hover
    {
        background: #DAE6E0;
    }
}

.btn-adicionar
{
    margin-top:15px;
    font-size:21px;
    font-weight: $font-bold;
    color:$green-light;
    background:url('/assets/frontoffice/images/icone-adicionar-50x50px.png') no-repeat;
    padding: 0 0 0 60px;
    line-height: 50px;
    display: inline-block;
    &:hover
    {
        color: $green-dark;
        background:url('/assets/frontoffice/images/icone-adicionar-50x50px.png') no-repeat;
    }
    &:after
    {
        content: '›››';
        display: inline-block;
        margin-left: 5px;
    }
}


.btn-action
{
    font-size: 19px;
    font-weight: $font-medium;
    color: #D4E4DD;
    background:$green-light;
    border-radius:0;
    padding: 5px 20px;
    &:hover
    {
        color: $white;
    }
}

.btn-get-quote
{
    margin-top:15px;
    margin-bottom:35px;
}

.produto-col-detalhes {
    .btn-get-quote
    {
        font-size: 14px;
    }
}

.btn-secondary
{
    font-size: 19px;
    font-weight: $font-medium;
    color: $green-darker;
    border:1px solid $gray-light;
    border-radius:0;
    padding: 5px 20px;
    background-color:none;
    &:hover
    {
        background-color:$gray-super-lighter;
    }
}

.btn-primary {
    background-color: $green-dark;
    border-color: $green-dark;
    &:hover
    {
        background-color: $green-light;
    }
}

.btn-light {
    border-color: $green-dark;
    &:hover
    {
        background-color: #e2e6ea;
    }
}

.btn-group-sm>.btn, .btn-group-xs>.btn, .btn-sm, .btn-xs {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
}