.carrinho-produtos-tabela{
    >thead{
        th{
            @extend .condensed;
            font-size: 13px;
            text-transform: uppercase;
            color:$gray-medium;
        }
    }
    >tbody{
        >tr{
            >td{
                padding: 8px;
                vertical-align: middle;
                background: $white;
                border-top:none;
                border-bottom:10px solid $body-background-color;
            }
        }
    }
    .right{
        text-align: right;
    }
    .center{
        text-align: center;
    }
    .col-fixed{
        width: 112px;
    }

    .titulo{
        font-size:17px;
        font-weight: $font-medium;
        a{
            color: $gray-dark;
            &:hover{
                color: $gray-light;
            }
        }
    }

    .preco{
        @extend .produto-col-preco;
        padding: 5px;
    }

    .referencia{
        @extend .produto-col-referencia;
        padding: 5px;
    }
    .green{
        color: $green-light;
    }

    .green-soft-bg
    {
        background:#e7eeea;
    }
}


.carrinho-total
{
    @extend .produto-col-preco;
    font-style:italic;
    .green
    {
        color:$green-light;
    }
}

.carrinho-total-valor
{
    @extend .produto-col-preco;
}