.produto-col
{
    @extend .col-xs-3;
    width: 194px;
    .produto-col-detalhes
    {
        background:$white;
        padding: 10px;
        text-align:center;
        height: 320px;
        overflow: hidden;
        border: 1px solid #FFFFFF;
    }
    margin-bottom:10px;
}


.produto-col-overlay
{
    position:relative;
    display:none;
    background-color: rgba(212, 228, 221, 0.7);
    position: absolute;
    top: 0;
    width: 184px;
    height: 100%;
    left: 5px;
}

.produto-col-detalhes:hover {
    /*.produto-col-overlay {
    top: 0;
    left: 5px;
    z-index:5;
    display: block;*/

    /*background-color: rgba(212, 228, 221, 0.7); */
    border: 1px solid #d6d6d6;
}

.produto-col-imagem
{
    padding-bottom:10px;
    border-bottom:1px solid $border-color;
}

.produto-col-referencia, .produto-detalhes-referencia
{
    @extend .condensed;
    margin:15px 0 0 0;
    padding: 0;
    font-size: 16px;
    color: $gray-medium;
    font-weight: $font-bold;
}

.produto-col-titulo
{
    @extend .condensed;
    margin:3px 0 0 0;
    padding: 0;
    font-size: 15px;
    color: $gray-dark;
    font-weight: $font-bold;
    line-height: 1.3em;
    min-height: 38px;
}

.produto-col-preco, .produto-detalhes-preco
{
    @extend .condensed;
    margin:15px 0 10px 0;
    font-size: 19px;
    color: $gray-dark;
    font-weight: $font-bold;
    padding:0;
}

a.produto-col-add
{
    font-size: 17px;
    font-weight: $font-bold;
    color:$green-light;
    &:hover
    {
        color:$green-darker;
    }
    &:before
    {
        content: '‹‹‹';
        display: inline-block;
        margin-right:5px;
    }
    &:after
    {
        content: '›››';
        display: inline-block;
        margin-left:5px;
    }
}

.produtos-col-sobconsulta
{
    font-size: 14px;
}

.produto-col-overlay-detalhes
{
    position:absolute;
    left:0;
    width:100%;
    background:none;
    padding-top:50px;
    padding-bottom:35px;
}

.produto-col-overlay-add
{
    position:absolute;
    left:0;
    top:145px;
    background:none;
    padding:35px 47px 35px 47px;
}

/*Detalhes*/

.produto-detalhes-col-imagens
{
    @extend .col-xs-3;
    width: 485px;
    background:$white;
    padding:10px;
}

.produto-detalhes-col-info
{
    float: right;
    @extend .col-xs-3;
    width: 250px;
    font-weight: $font-light;
}

.produto-detalhes-preco
{
    margin-top: 5px;
}

.produto-detalhes-referencia
{
    font-size:19px;
    margin-top: 0;
}

.produtos-detalhes-referencia-label
{
    color: $gray-lighter;
    font-size: 14px;
    font-weight: $font-light;
}

.produtoQuantidadeAdd
{
    width:80px !important;
}

.produto-share
{
    padding: 25px 0;
    margin-left: -5px;
}

.produto-nav-tabs
{
    margin-top:25px;
    border-bottom: 1px solid $nav-tabs-active-link-hover-border-color;
      > li {
        > a {
            color: $gray-lighter;
            font-weight: $font-medium;
            font-size: 15px;
            padding:12px 20px;

            margin-right: 2px;
            line-height: $line-height-base;
            border: 1px solid $nav-tabs-active-link-hover-border-color;
            border-radius: 20px 20px 0 0;
          &:hover {
            border: 1px solid $nav-tabs-active-link-hover-border-color;
            color: $gray-dark;
            background-color: $body-background-color;
          }
        }

        // Active state, and its :hover to override normal :hover
        &.active > a {
          &,
          &:hover,
          &:focus {
            background-color: $body-background-color;
            border: 1px solid $nav-tabs-active-link-hover-border-color;
            border-bottom-color: transparent;
            cursor: default;
            color: $gray-dark;
          }
        }
      }

}
.produto-tabs
{
    .tab-pane
    {
        padding-top:25px;
    }
}

.produto-tab-info
{
    font-weight: $font-light;
    font-size:17px;
    padding: 15px;
}


.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus
{
    background:#1d8052;
    border-color: #1d8052;
}

.outlet-mensagem
{
    margin-bottom:15px;
    padding:15px;
}