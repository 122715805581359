.modalCarrinho
{
    .modal-dialog
    {
        margin: 200px auto;
        width: 185px;
    }
    .modal-content
    {
        border-radius: 0;
    }
}

.modalCarrinho
{
    text-align: center;
    .mCAReferencia
    {
        @extend .produto-col-referencia;
    }
    .mCATitulo
    {
        @extend .produto-col-titulo;
    }
    .mCAPreco
    {
        @extend .produto-col-preco;
    }

    .mCAImagem
    {
        @extend .produto-col-imagem;
    }
}

.modal-icone-carrinho
{
    position:absolute;
    top:-50px;
    left: 47px;
}

.modal-msg-adicionado
{
    padding: 10px;
    padding-top:50px;
    background: #D4E4DD;
    font-weight: $font-light;
    font-size: 15px;
    color: $green-light;
}

.modal-produto-info
{
    padding:10px;
}

.modalProdutoQuote, .modalResellerProdutoQuote
{
    .modal-dialog
    {
        margin: 200px auto;
        width: 470px;
    }
    .modal-content
    {
        border-radius: 0;
    }
    
    form
    {
        label
        {
            text-align:right;
        }
    }

    .telefones {
        margin:0 auto;
        margin-top: 25px;
        width: 275px;
        background:url('/assets/frontoffice/images/icone-contactos-fundo-branco.png') no-repeat 0 0;
        text-align: right;

        p
        {
            padding-left: 96px;
            line-height: 27px;
        }

        a
        {
            font-weight: $font-bold;
            color: $green-dark;
            font-size: 29px;
        }

        p.horario
        {
            margin-top: 25px;
            margin-bottom: 25px;
            padding-left:0;
            padding-top:5px;
            font-size: 15px;
            font-weight: 300;
            color: $gray-dark;
            text-align: center;
            border-top:1px solid $gray-dark;
        }
    }
}

.modalResellerProdutoQuote {
    .modal-dialog
    {
        margin: 200px auto;
        width: 500px;
    }
}

.modal-icone-quote
{
    z-index:9999;
    position:absolute;
    top:-50px;
    left: 190px;
}

.modal-msg-quote
{
    padding: 10px;
    padding-top:50px;
    background: #D4E4DD;
    font-weight: $font-light;
    font-size: 15px;
    color: $green-light;
}

.modalEnviosInternacionais
{
    .modal-icone-quote
    {
        left: 465px;
    }

    .modal-dialog
    {
        margin: 200px auto;
        width: 1020px;
    }
    .modal-content
    {
        border-radius: 0;
        height: 690px;
        background:url('/assets/frontoffice/images/overlay-brasil-bg.jpg') no-repeat;
    }
    
    .modal-msg-title
    {
        font-size: 35px;
        text-align: center;
        color: $green-light;
        height:130px;
        padding-top: 60px;
        font-weight: 700;
    }
    
    .modal-msg {
        float:left;
        margin-top:200px;
        margin-left:395px;
        width: 570px;
        color:#D6D6D6;
        font-size: 16px;
    
    }
    
    .modal-continue {
        margin-top: 55px;
    }
}


.modalClienteSubscreverNewsletter
{
    .modal-icone-quote
    {
        left: 250px;
    }

    .modal-dialog
    {
        width: 600px;
        margin-top: 200px;
    }

    .modal-content {
        padding: 15px;
        padding-top: 50px;
    }
    
    .modal-msg-title
    {
        font-size: 35px;
        text-align: center;
        height:130px;
        padding-top: 60px;
        font-weight: 700;
    }
    
    .modal-msg {
        width: 570px;    
    }
    
    .modal-continue {
        margin-top: 15px;
    }
}


.modal-support-icon-wrapper {
    position: fixed; 
    bottom: 25px;
    right: 25px;

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.modal-support-icon-wrapper__move { 
    right: -60px;
}

.modal-support-window {
    position: fixed; 
    width: 250px; 
    bottom: 10%; 
    right: -300px; 
    background: #FFF; 
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.1);

    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;

    .modal-support-window-icon {
        position: absolute; 
        top: -24px; 
        left: -24px;
    }

    .modal-support-window-header {
        background: #d4e4dd; 
        color:#1d8052; 
        padding: 15px 15px 30px 15px; 
        font-weight: bold; 
        font-weight: 500;
        h4 {
            margin: 0; 
            padding-left: 35px;
        }
    }

    .modal-support-window-close {
        color:#1d8052;
    }

    .modal-support-window-body {
        padding: 15px; 
        font-size: 12px;
    }
}

.modal-support-window__move { 
    right: 25px;
}