/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Feb 19, 2015, 2:44:52 PM
    Author     : claudiofranco
*/

.newsletter-subscrever {
    border-bottom:1px solid $border-color;
    box-shadow: 0px 2px 0 $white;
    padding:30px 0;
    
    h3 {
        text-align: center;
    }
    
    .btn {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: $font-medium;
        text-align: center;
        &:hover {
            text-decoration: none;
        }
    }
    
    input {
        text-align: center;
    }
}