.pagina
{
    padding: 35px 20px;
    background: $white;

    font-size: 16px;
    font-weight: $font-light;
    line-height: 1.4em;

    p
    {
        padding-bottom:25px;
    }
}