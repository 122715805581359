.areapessoal-row
{
    background:url('/assets/frontoffice/images/row-white-50.png');
    margin-bottom:10px;
}

.areapessoal-row-full
{
    background: #FFFFFF;
    margin-bottom:  10px;
}

.areapessoal-col
{
    padding: 25px;
    p
    {
        font-weight: $font-light;
    }
}