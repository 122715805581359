.col-main
{
    float:left;
    width:766px;
}

.col-main-wide
{
    padding-bottom:55px;
}

.disclaimer-preco-iva
{
    font-size:14px;
    font-weight: $font-light;
    color: #646464;
    padding: 60px 0;
    text-align: center;
}

.msg-no-results
{
    padding:20px;
    text-align:center;
}

.msg-operation
{
    padding: 20px;
    text-align:center;
    margin:20px 0;
}