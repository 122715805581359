// Header
.site-header
{
    .topbar
    {
        background: #323232;
        height: 50px;
        color: $gray-super-lighter;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: $font-light;
        text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.5);
        a
        {
            padding-right:0;
            padding-left:0;
            color: $gray-super-lighter;
            &:hover
            {
                color: #7A8A80;
                background:none;
            }
            &:after
            {
                color: $gray-super-lighter;
                content: '|';
                display: inline-block;
                margin-left: 15px;
                margin-right: 15px;
            }
            &.none
            {
                &:after
                {
                    content: '';
                    margin:0;
                }
            }
        }

    }

    .middlebar
    {
        height: 200px;
        background:url('/assets/frontoffice/images/headers/header-1-simulado.jpg') no-repeat center;
        &:after
        {
            content: ' ';
            display: block;
            width: 100%;
            height: 12px;
            background:url('/assets/frontoffice/images/shadow-sobre-header.png') no-repeat center;
            margin-top: -12px;
        }
        .logo
        {
            position:relative;
            width:277px;
            height: 144px;
            margin:0;
            top: -35px;
            a
            {
                width:277px;
                height: 144px;
                display: inline-block;
                text-indent: -9999px;
                background:url('/assets/frontoffice/images/logo_website_2019.png') no-repeat;
            }
        }

        .assinatura
        {
            position:relative;
            top: -30px;
            margin:0;
            line-height: 0.7;
            .assinatura-text
            {
                display: block;
                width: 340px;
                height: 34px;
                text-indent: -9999px;
                background:url('/assets/frontoffice/images/para_nao_os_deixar_parar_2017.png') no-repeat;
            }

            .maideprodutos
            {
                font-size: 15px;
                color: $white;
                font-weight: $font-light;
                text-transform: uppercase;
                line-height: 13px;
            }
        }

        .telefones
        {
            float: right;
            margin-top: 60px;
            width: 275px;
            background:url('/assets/frontoffice/images/icone-telefones.png') no-repeat 0 0;
            text-align: right;

            div
            {
                float: right;
                line-height: 24px;
                text-align: right;
                padding-top: 5px;
            }

            a
            {
                font-size: 11px;
                font-weight: $font-bold;
                color: $white;
                font-size: 31px;
                text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.5);
            }

            span {
                color: $white;
                font-size: 11px;
            }

            div.horario
            {
                width: 100%;
                float: left;
                margin-left: 0;
                padding-left:0;
                margin-top:15px;
                font-size: 15px;
                font-weight: 300;
                color: $white;
                text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.5);
                text-align: center;
                border-top:1px solid $white;
            }
        }
    }

    .bottombar
    {
        background:url('/assets/frontoffice/images/shadow-clara.png') no-repeat bottom center;
        a
        {
            color: $gray-dark;
            font-size:15px;
            text-transform: uppercase;
            font-weight: $font-medium;
            padding-top:30px;
            padding-bottom:30px;
            &:hover
            {
                color:$gray-light;
                background:none;
            }
        }

        ul
        {
            li
            {
                a
                {
                    padding-top:30px;
                    padding-bottom:35px;
                    padding-left:15px;
                    padding-right:11px;
                }
                &.active a
                {
                    color:$gray-light;
                    background:none;
                }
            }
        }

        li+li
        {
            &:before
            {
                content: '|';
                float: left;
                margin-top: 28px;
                font-size: 16px;
                color: #999999;
            }
        }

        form
        {
            margin-top: 15px;
            padding-left: 0;
            input.form-control
            {
                border-radius:0;
                color: $gray-light;
                font-size: 14px;
                font-weight: 300;
                height: 47px;
                width: 215px;
                background:$body-background-color;
            }
            button
            {
                background:none;
                border:none;
                font-size: 27px;
                color: $gray-dark;
                &:hover
                {
                    color: $gray-light;
                }
            }
        }
    }
}

.navbar-right
{
    &:last-child
    {
        margin-right: 0;
    }
}

.navbar-client-top {
    list-style: none;
    padding-top: 15px;
}