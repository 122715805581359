.hlight
{
    color: $gray-lighter;
    font-weight: $font-light;
    font-size: 19px;
    text-transform: uppercase;
    margin: 0 0 25px 0;
}

.hmedium,.hmedium-green
{
    color: $gray-dark;
    font-weight: $font-medium;
    font-size: 19px;
    margin: 0 0 25px 0;
}

.hmedium-green
{
    color:$green-light;
}

.txtcenter
{
    text-align: center;
}

.txtright
{
    text-align: right;
}