.apoio
{
  margin-bottom:15px;
  h4
  {
      margin-bottom:0;
      a{
          color: $gray-dark;
          &.active
          {
              color: $green-dark;
          }
      }
  }
  
  .apoio-resposta
  {
      padding-top:20px;
      display:none;
  }
}