footer
{
    background: $footer-background;

    .nav-pills{
        margin: 15px 0;
        border-bottom:1px solid #505152;
        box-shadow: 0px 1px 0 #313131;

        >li{
            float:none;
            display:inline-block;
            a{
                color: $gray-light;
                font-weight: $font-light;
                font-size: 17px;
                padding-left:15px;
                padding-right:11px;
                padding-bottom: 15px;
                &:hover{
                    color: $gray-lighter;
                    background:none;
                }
            }
        }
        li+li
        {
            &:before
            {
                content: '|';
                float: left;
                margin-top: 10px;
                font-size: 17px;
                color: $gray-light;
            }
        }
    }

    .nav-pills {
        text-align:center;
    }

    .assinatura
    {
        margin:0;
        padding: 100px 0 35px 0;
        text-align: center;
        color: $gray-light;
        font-weight: $font-light;
        font-size: 14px;
        a{
            color: $gray-light;
            &:hover{
                color: $gray-lighter;
            }
        }
    }
}