.form-registo, .form-login
{
    .control-label
    {
        @extend .condensed;
        font-size: 13px;
        text-transform: uppercase;
        color: $gray-medium;
    }

    input.form-control, textarea.form-control
    {
        border-radius: 0;
        font-size: 13px;
        font-weight: $font-light;
        color: $gray-dark;
    }


    .has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error input.form-control, .has-error textarea.form-control {
        color: #EF5050;
    }

    .has-error input.form-control, .has-error textarea.form-control{
        background: #f3e6e6;
    }
}

.form-login
{
    .col-xs-2,.col-xs-3,.col-xs-4,.col-xs-5,.col-xs-6,.col-xs-7,.col-xs-8,.col-xs-9,.col-xs-10,.col-xs-11,.col-xs-12
    {
        margin-bottom: 10px;
    }
}

.alert-info 
{
    background-color: #f7f7f7;
    border-color: #d6d6d6;
    color: #646464;
}

.placeholder { color: #aaa; }

.google-recaptcha-disclaimer {
    font-size: 11px; 
    color: #6d6d6d;
}

.grecaptcha-badge { visibility: hidden; }
