/*CORES*/

/*LINKS*/
$gray-dark:#2D2D2D;
$gray-medium:#8E8E8E;
$gray-light:#999999;
$gray-lighter:#ABABAB;

$gray-super-lighter:#CFCFCF;

$green-darker:#135436;
$green-dark:#006640;
$green-light:#1D8052;

$white: #FFFFFF;

$font-light:300;
$font-medium:500;
$font-bold:700;
$font-normal:400;

$body-background-color:#F7F7F7;

$nav-link-hover-bg: #FFFFFF;
$nav-pills-active-link-hover-bg: #FFFFFF;

$border-color:#d6d6d6;

$nav-tabs-active-link-hover-border-color: #d6d6d6;

$footer-background:#323232;