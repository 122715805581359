.col-categorias
{
    float: left;
    width: 225px;
    margin-right: 29px;
    margin-bottom:20px;

    ul.categorias
    {
        margin: 15px 0 0 0;
        list-style:none;
        border-bottom:1px solid $border-color;
        box-shadow: 0px 2px 0px $white;
        padding-bottom: 25px;
        li
        {
            margin: 0;
            &.active>a
            {
                font-weight: $font-medium;
                color: $gray-dark;
                &:hover
                {
                    color: $gray-dark;
                }
            }
            a
            {
                display:block;
                color: $gray-dark;
                font-size: 15px;
                font-weight: $font-light;
                padding:4px 10px;
                border-radius:0;
                &:hover
                {
                    font-weight: $font-medium;
                }
                &:after
                {
                    content: '›';
                    display: inline-block;
                    margin-left:5px;
                }
            }

            ul
            {
                list-style: none;
                padding: 5px;
                background-color:$white;
                li
                {
                    a
                    {
                        font-size: 14px;
                        padding: 3px 10px;
                        &:after
                        {
                            content: none;
                        }
                        &:before
                        {
                            content: '·';
                            display: inline-block;
                            margin-right:5px;
                        }
                    }
                }
            }
        }
    }

    .nav-tabs {
      border-bottom: 1px solid $nav-tabs-active-link-hover-border-color;
      > li {
        > a {
            color: $gray-dark;
            font-weight: $font-medium;
            font-size: 15px;
            padding:12px 20px;

            margin-right: 2px;
            line-height: $line-height-base;
            border: 1px solid transparent;
            border-radius: 20px 20px 0 0;
          &:hover {
            border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
            color: $gray-lighter;
          }
        }

        // Active state, and its :hover to override normal :hover
        &.active > a {
          &,
          &:hover,
          &:focus {
            background-color: $body-background-color;
            border: 1px solid $nav-tabs-active-link-hover-border-color;
            border-bottom-color: transparent;
            cursor: default;
            color: $gray-dark;
          }
        }
      }
    }

    .banners-esquerda, .metodos-pagamento
    {
        border-bottom:1px solid $border-color;
        box-shadow: 0px 2px 0 $white;
        padding:30px 0;
    }

    .metodos-pagamento {
        margin-bottom: 25px;
    }

    .livro-reclamacoes {
        margin-bottom: 25px; 
        border-bottom: 1px solid #d6d6d6; 
        box-shadow: 0 2px 0 #fff; 
        padding: 0 0 30px 0;
    }

    .facebook
    {
        padding:30px 0;
        padding-top: 15px;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        box-shadow: 0 2px 0 #fff;
        h4
        {
            margin:0;
            font-size: 21px;
            font-weight: $font-bold;
            padding: 20px 0 10px 0;
        }
        ul
        {
            list-style: none;
            padding: 0;
            margin:0;
            margin-top: 15px;
            li{
                font-size: 16px;
                font-weight: $font-light;
                &:after,&:before{
                    content: '.';
                    display: inline-block;
                    margin:0 2px;
                }
            }
        }
    }
}