.marcas-slider
{
     padding: 30px 0;
     background:$white;
     &:before
        {
            content: ' ';
            display: block;
            width: 100%;
            height: 7px;
            background:url('/assets/frontoffice/images/shadow-clara.png') no-repeat center;
            position: relative;
            top: -37px;
        }
}


/* Container DIV - automatically generated */
.simply-scroll-container {
    position: relative;
}

/* Clip DIV - automatically generated */
.simply-scroll-clip {
    position: relative;
    overflow: hidden;
}

/* UL/OL/DIV - the element that simplyScroll is inited on
Class name automatically added to element */
.simply-scroll-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
}

.simply-scroll-list li {
    padding: 0;
    margin: 0;
    list-style: none;
    /*width: 110px;*/
    margin-right: 25px;
}

.simply-scroll-list li img {
    border: none;
    display: inline-block;
    text-align: center;
}

/* Custom class modifications - adds to / overrides above

.simply-scroll is default base class */

/* Container DIV */
.simply-scroll {
    width: 100%;
    height: 70px;
    margin-bottom: 1em;
}

/* Clip DIV */
.simply-scroll .simply-scroll-clip {
    width: 100%;
    height: 70px;
}

/* Explicitly set height/width of each list item */ 
.simply-scroll .simply-scroll-list li {
    float: left; /* Horizontal scroll only */
    height: 70px;
    text-align: center;
    margin-right: 25px;
}